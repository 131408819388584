








import { Component, Vue } from "vue-property-decorator";
import MunicipioInforma from "@/components/Servicios/MunicipioInforma.vue";

@Component({
  components: {
    MunicipioInforma
  },
  meta: {
    // sets document title
    title: "Municipio Informa - Municipalidad de Arica",
    // optional; sets final title as "Index Page - My Website", useful for multiple level meta
    //titleTemplate: title => `${title} - My Website`,

    // meta tags
    meta: {
      description: {
        name: "description",
        content: "Revisa aquí información del municipio."
      },
      keywords: { name: "keywords", content: "Arica" },
      equiv: {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8"
      }
    }
  }
})
export default class ServiciosView extends Vue {
  private mounted() {
    document.title = "Municipio Informa - Municipalidad de Arica";

    var canonicalLink = document.createElement("link");
    // Establece los atributos del enlace

    canonicalLink.rel = "canonical";
    canonicalLink.href = "https://muniarica.cl/municipio/avisos";
    document.head.appendChild(canonicalLink);
  }
}
